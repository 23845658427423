import React, { useContext, useEffect, useState } from "react";
import styles from "./Map.module.css";
import GoogleMapReact from "google-map-react";
import { ReactComponent as List } from "../../assets/icons/List.svg";
import { ReactComponent as VehiclePin } from "../../assets/icons/VehiclePin.svg";
import { ReactComponent as SelectedVehiclePin } from "../../assets/icons/SelectedVehiclePin.svg";
import { ReactComponent as Warning } from "../../assets/icons/Warning.svg";
import CommonButton from "../common/CommonButton";
import MapButtons from "../common/MapButtons";
import { SearchContext } from "../../context/search/provider";
import { useHistory } from "react-router-dom";
import ExpandableList from "../search/ExpandableList";
import VehicleDetails from "./VehicleDetails";
import FoundListItem from "../search/FoundListItem";
import SearchListItem from "../search/SearchListItem";
import AddVehicle from "./AddVehicle";
import UnfoundWarning from "./UnfoundWarning";
import { DESKTOP_BREAKPOINT } from "../../utils/constants";
export default function Map() {
  const {
    initializeState,
    foundVehicles,
    unfoundVehicles,
    removeFromFound,
    removeFromUnfound,
    detailVehicle,
    setDetailVehicle,
    editUnfoundId,
  } = useContext(SearchContext);
  const [width, setWidth] = useState(window.innerWidth);
  const [isSearchListVisible, setIsSearchListVisible] = useState(false);
  const [isUnfoundVisible, setisUnfoundVisible] = useState(false);
  const [wasVehicleAdded, setWasVehicleAdded] = useState(false);
  const [expandedList, setExpandedList] = useState(
    foundVehicles.length ? "found" : "unfound"
  );

  const history = useHistory();

  const startNewSearch = () => {
    initializeState();
    history.push("/");
  };

  const [pinCenter, setPinCenter] = useState();
  const [isTerrainView, setIsTerrainView] = useState(false);

  useEffect(() => {
    if (foundVehicles.length) {
      let latArr = foundVehicles.map((v) => v.lat);
      let lngArr = foundVehicles.map((v) => v.lng);

      const latAverage =
        latArr.reduce((a, b) => a + b, 0) / foundVehicles.length;
      const lngAverage =
        lngArr.reduce((a, b) => a + b, 0) / foundVehicles.length;
      setPinCenter({ lat: latAverage, lng: lngAverage });
    }
  }, [foundVehicles]);

  useEffect(() => {
    const resizeFunction = (e) => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", resizeFunction);

    return () => window.removeEventListener("resize", resizeFunction);
  }, []);

  useEffect(() => {
    if (width < DESKTOP_BREAKPOINT) {
      if (isSearchListVisible) {
        setDetailVehicle({});
        setisUnfoundVisible(false);
      }
      isSearchListVisible && setDetailVehicle({});
    }
    // eslint-disable-next-line
  }, [isSearchListVisible]);

  useEffect(() => {
    if (width < DESKTOP_BREAKPOINT) {
      if (detailVehicle.id) {
        setIsSearchListVisible(false);
        setisUnfoundVisible(false);
      }
    }
    // eslint-disable-next-line
  }, [detailVehicle.id]);

  useEffect(() => {
    if (!unfoundVehicles.length && !wasVehicleAdded) {
      setisUnfoundVisible(false);
    }
  }, [unfoundVehicles, wasVehicleAdded]);

  useEffect(() => {
    let timer;

    if (wasVehicleAdded) {
      timer = setTimeout(() => {
        setWasVehicleAdded(false);
      }, 2500);
    }

    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [wasVehicleAdded]);
  return (
    <div className={styles.page}>
      <div className={styles.panel}>
        <div>
          {width >= DESKTOP_BREAKPOINT && <AddVehicle />}
          {(isSearchListVisible ||
            (width >= DESKTOP_BREAKPOINT && !!foundVehicles.length)) && (
            <ExpandableList
              title={`VEHICLE SEARCH LIST (${foundVehicles.length})`}
              icon={<List />}
              removeIds={removeFromFound}
              listItems={foundVehicles}
              expanded={width < DESKTOP_BREAKPOINT || expandedList === "found"}
              ListItem={FoundListItem}
              style={width < DESKTOP_BREAKPOINT ? { margin: 0 } : {}}
              name="found"
              setExpandedList={(name) => {
                setExpandedList(name);
                setDetailVehicle({});
              }}
              topSection={
                width < DESKTOP_BREAKPOINT && (
                  <>
                    <AddVehicle windowWidth={width} />
                    {!!unfoundVehicles.length && (
                      <UnfoundWarning
                        seeList={() => {
                          setisUnfoundVisible(true);
                          setIsSearchListVisible(false);
                        }}
                      />
                    )}
                  </>
                )
              }
            />
          )}
          {(isUnfoundVisible ||
            (width >= DESKTOP_BREAKPOINT && !!unfoundVehicles.length)) && (
            <ExpandableList
              title={`NOT FOUND VEHICLES (${unfoundVehicles.length})`}
              headerStyles={{ color: "var(--warning)" }}
              icon={<Warning fill={"var(--warning)"} />}
              removeIds={removeFromUnfound}
              editFunction={(id, input) => {
                let res = editUnfoundId(id, input);
                if (res.success) {
                  setWasVehicleAdded(true);
                }
                return res;
              }}
              listItems={unfoundVehicles}
              expanded={
                width < DESKTOP_BREAKPOINT || expandedList === "unfound"
              }
              ListItem={SearchListItem}
              message={wasVehicleAdded && "Vehicle Added to Search List"}
              onItemClick={setDetailVehicle}
              name="unfound"
              setExpandedList={(name) => {
                setExpandedList(name);
                setDetailVehicle({});
              }}
            />
          )}
        </div>
        {detailVehicle.id && <VehicleDetails />}
        <div className={styles.tabletButtons}>
          <CommonButton
            version="secondary"
            label="Start a New Search"
            onClick={startNewSearch}
          />
          <CommonButton
            type={width > 620 || "icon"}
            version={isSearchListVisible ? "primary" : "secondary"}
            label={
              width > 620 && `Vehicle Search List (${foundVehicles.length})`
            }
            icon={<List fill={isSearchListVisible ? "white" : undefined} />}
            onClick={() => setIsSearchListVisible((current) => !current)}
          />
        </div>
      </div>

      <div className={styles.map}>
        {width > DESKTOP_BREAKPOINT && (
          <>
            <CommonButton
              version="secondary"
              type="newSearch"
              label="Start a New Search"
              onClick={startNewSearch}
              style={{
                position: "absolute",
                top: 26,
                right: 16,
                zIndex: 1,
                marginTop: 0,
              }}
            />
            <MapButtons
              setIsTerrainView={setIsTerrainView}
              isTerrainView={isTerrainView}
            />
          </>
        )}

        <MapButtons
          setIsTerrainView={setIsTerrainView}
          isTerrainView={isTerrainView}
        />

        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            mapIds: [process.env.REACT_APP_GOOGLE_MAPS_MAP_ID],
          }}
          center={{
            lat: detailVehicle?.lat || pinCenter?.lat || 40.3797,
            lng: detailVehicle?.lng || pinCenter?.lng || -86.7995,
          }}
          zoom={detailVehicle?.lat ? 18 : 17}
          options={() => {
            return {
              mapTypeId: isTerrainView ? "roadmap" : "satellite",
              gestureHandling: "greedy",
              mapId: process.env.REACT_APP_GOOGLE_MAPS_MAP_ID,
              disableDefaultUI: true,
              rotateControl: false,
              tilt: 0,
            };
          }}
        >
          {foundVehicles.map((v) =>
            v.id === detailVehicle?.id ? (
              <SelectedVehiclePin
                key={v.id}
                lat={v.lat}
                lng={v.lng}
                className="blue pointer"
                onClick={() => setDetailVehicle({})}
                style={{
                  position: "relative",
                  right: 22,
                  top: -44,
                }}
              />
            ) : (
              <VehiclePin
                key={v.id}
                lat={v.lat}
                lng={v.lng}
                className="blue pointer"
                onClick={() => {
                  setDetailVehicle(v);
                  setExpandedList("found");
                }}
                style={{
                  position: "relative",
                  right: 12,
                  top: -24,
                }}
              />
            )
          )}
        </GoogleMapReact>
      </div>
    </div>
  );
}
