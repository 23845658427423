import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Layout from "./components/Layout";
import Search from "./components/search/Search";
import Map from "./components/map/Map";
import "./custom.css";
import ComponentBoard from "./utils/ComponentBoard/ComponentBoard";

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Layout>
        <Switch>
          <Route path="/" exact component={Search} />
          <Route path="/map" exact component={Map} />
          <Route path="/dev" exact component={ComponentBoard} />
          <Route>
            <Redirect to={"/"} />
          </Route>
        </Switch>
      </Layout>
    );
  }
}
