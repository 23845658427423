export const database = [
  {
    VIN: "4S3BWAB6XN3009082",
    LAT: 40.377585,
    LNG: -86.803263,
    VIN8: "N3009082",
    VCN: 4567890,
  },
  {
    VIN: "4S3BWAB65N3009085",
    LAT: 40.37849,
    LNG: -86.80305,
    VIN8: "N3009085",
    VCN: 3456789,
  },
  {
    VIN: "4S3BWAB68N3009100",
    LAT: 40.3774,
    LNG: -86.80254,
    VIN8: "N3009100",
    VCN: 2345678,
  },
  {
    VIN: "4S3BWAD60N3009167",
    LAT: 40.378235,
    LNG: -86.802566,
    VIN8: "N3009167",
    VCN: 1234567,
    model: "Outback",
    color: "Blue",
  },
  {
    VIN: "4S3BWAC66N3009160",
    LAT: 40.37836,
    LNG: -86.802591,
    VIN8: "12345678",
    VCN: 3101060,
    color: "White",
  },
  {
    VIN: "4S3BWAC62N3009236",
    LAT: 40.377686,
    LNG: -86.803286,
    VIN8: "N3009236",
    VCN: 3101070,
  },
  {
    VIN: "4S3BWAB63N3009263",
    LAT: 40.37833,
    LNG: -86.802791,
    VIN8: "N3009263",
    VCN: 3010570,
  },
  {
    VIN: "4S3BWAD66N3009271",
    LAT: 40.378011,
    LNG: -86.8027,
    VIN8: "N3009271",
    VCN: 3111240,
  },
  {
    VIN: "4S3BWAB68N3009307",
    LAT: 40.378346,
    LNG: -86.802705,
    VIN8: "N3009307",
    VCN: 3010580,
  },
];
