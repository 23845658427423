import React, { useContext, useEffect, useState } from "react";
import styles from "./SearchListItem.module.css";
import { ReactComponent as Remove } from "../../assets/icons/Remove.svg";
import { ReactComponent as CloseSmall } from "../../assets/icons/CloseSmall.svg";
import { ReactComponent as Edit } from "../../assets/icons/Edit.svg";
import { ReactComponent as Check } from "../../assets/icons/Check.svg";
import CommonButton from "../common/CommonButton";
import { SearchContext } from "../../context/search/provider";

export default function SearchListItem({ item, remove, edit, onClick }) {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(item.id);
  const [errorMessage, setErrorMessage] = useState("");
  const { detailVehicle } = useContext(SearchContext);

  useEffect(() => {
    let timeout;
    if (errorMessage) {
      timeout = setTimeout(() => setErrorMessage(""), 2500);
    }

    return () => clearTimeout(timeout);
  }, [errorMessage]);

  useEffect(() => {
    setInputValue(item.id);
  }, [isEditing, item.id]);

  const handleEdit = () => {
    const result = edit(item, inputValue);
    if (result?.error) {
      setErrorMessage(result.error);
    }
    return result;
  };

  return (
    <div
      className={`${styles.outerWrapper} ${onClick && styles.clickable} ${
        detailVehicle.id === item.id && styles.selected
      }`}
      onClick={onClick}
    >
      <div className={styles.container}>
        <div>
          {item.type && item.type !== "invalid" && (
            <div className="label-text">{item.type}</div>
          )}
          {isEditing ? (
            <input
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              style={{ textTransform: "uppercase" }}
              onKeyPress={(e) =>
                e.key === "Enter" && handleEdit(item, inputValue)
              }
            />
          ) : (
            item.id
          )}
        </div>
        <div className="d-flex row align-items-center">
          {edit && (
            <CommonButton
              type="text"
              label={isEditing ? "" : "Edit"}
              icon={
                isEditing ? (
                  <Check className="blue" />
                ) : (
                  <Edit className="blue" />
                )
              }
              onClick={() => {
                const result = isEditing && handleEdit(item, inputValue);
                if (!isEditing || !result.error) {
                  setIsEditing((current) => !current);
                }
              }}
              className="mr-2"
            />
          )}
          {isEditing ? (
            <CloseSmall
              onClick={() => setIsEditing(false)}
              className={styles.remove}
            />
          ) : (
            <Remove onClick={() => remove([item])} className={styles.remove} />
          )}
        </div>
      </div>
      <div className={styles.error}>{errorMessage}</div>
    </div>
  );
}
