import React, { useContext } from "react";
import styles from "./FoundListItem.module.css";
import { ReactComponent as Remove } from "../../assets/icons/Remove.svg";
import { SearchContext } from "../../context/search/provider";

export default function FoundListItem({ item, remove }) {
  const { detailVehicle, setDetailVehicle } = useContext(SearchContext);
  return (
    <div
      className={`${styles.container} ${
        detailVehicle.id === item.id && styles.selected
      }`}
      onClick={() => setDetailVehicle(item)}
    >
      <div>
        {item.type && item.type !== "invalid" && (
          <div className="label-text">{item.type}</div>
        )}
        {item.id}
      </div>
      <div className="d-flex row align-items-center">
        <Remove
          onClick={(e) => {
            e.stopPropagation();
            remove([item]);
          }}
          className={styles.remove}
        />
      </div>
    </div>
  );
}
