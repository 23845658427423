import { validateId } from "../../utils/validationHelper";
import { database } from "../../utils/dummyData";
import { searchVehiclesDb } from "../../utils/api";

const actionTypes = {
  SET_STATE: "SET_STATE",
  SET_INPUT_VALUE: "SET_INPUT_VALUE",
  SET_SEARCH_LIST: "SET_SEARCH_LIST",
  SET_INVALID_IDS: "SET_INVALID_IDS",
  INIT_STATE: "INIT_STATE",
};

const getActions = (state, dispatch) => {
  const initializeState = () => {
    dispatch({
      type: actionTypes.INIT_STATE,
    });
  };

  const setInputValue = (value) => {
    dispatch({
      type: actionTypes.SET_INPUT_VALUE,
      payload: value,
    });
  };

  const validateAndAddToContext = (searchInput) => {
    const searchList = state.searchList.map((id) => id.id);
    const invalidIds = state.invalidIds.map((id) => id.id);
    let inputs = searchInput.split(",");
    const valid = [];
    const invalid = [];
    const duplicate = [];
    inputs.forEach((input) => {
      input = validateId(input);

      if (invalidIds.includes(input.id) || searchList.includes(input.id)) {
        duplicate.push(input);
      } else if (input.type === "invalid") {
        invalid.push(input);
      } else {
        valid.push(input);
      }
    });

    dispatch({
      type: actionTypes.SET_STATE,
      payload: {
        searchList: [...state.searchList, ...valid],
        invalidIds: [...state.invalidIds, ...invalid],
        inputValue: "",
      },
    });

    return { validIds: valid, invalidIds: invalid, duplicateIds: duplicate };
  };

  const removeFromSearchList = (ids) => {
    ids = ids.map((id) => id.id);
    let searchList = state.searchList;
    searchList = searchList.filter(({ id }) => !ids.includes(id));
    dispatch({
      type: actionTypes.SET_SEARCH_LIST,
      payload: searchList,
    });
  };

  const removeFromFound = (vehicles) => {
    vehicles = vehicles.map((vehicle) => vehicle.id);
    let foundVehicles = state.foundVehicles;
    foundVehicles = foundVehicles.filter(
      (vehicle) => !vehicles.includes(vehicle.id)
    );
    dispatch({
      type: actionTypes.SET_STATE,
      payload: { foundVehicles },
    });
  };

  const removeFromUnfound = (vehicles) => {
    vehicles = vehicles.map((vehicle) => vehicle.id);
    let unfoundVehicles = state.unfoundVehicles;
    unfoundVehicles = unfoundVehicles.filter(
      (vehicle) => !vehicles.includes(vehicle.id)
    );
    dispatch({
      type: actionTypes.SET_STATE,
      payload: { unfoundVehicles },
    });
  };

  const removeInvalidIds = (ids) => {
    ids = ids.map((id) => id.id);
    let invalidIds = state.invalidIds;
    invalidIds = invalidIds.filter(({ id }) => !ids.includes(id));
    dispatch({
      type: actionTypes.SET_INVALID_IDS,
      payload: invalidIds,
    });
  };

  const clearSearchList = () => {
    dispatch({
      type: actionTypes.SET_SEARCH_LIST,
      payload: [],
    });
  };

  const editInvalidId = (id, inputValue) => {
    const newIdObj = validateId(inputValue.trim());
    const searchList = state.searchList.map((id) => id.id);
    const invalidIds = state.invalidIds;
    const idx = invalidIds.findIndex((i) => i.id === id.id);

    if (searchList.includes(inputValue))
      return { error: "Duplicate Identifier" };

    const valid = [];
    if (newIdObj.type === "invalid") {
      invalidIds.splice(idx, 1, newIdObj);
    } else {
      valid.push(newIdObj);
      invalidIds.splice(idx, 1);
    }
    dispatch({
      type: actionTypes.SET_STATE,
      payload: {
        searchList: [...state.searchList, ...valid],
        invalidIds,
        inputValue: "",
      },
    });
  };

  const editUnfoundId = (id, inputValue) => {
    const newIdObj = validateId(inputValue.trim());
    if (newIdObj.type === "invalid") return { error: "Invalid identifier" };
    const foundList = state.foundVehicles.map((id) => id.id);
    const unfoundVehicles = state.unfoundVehicles;
    const idx = unfoundVehicles.findIndex((i) => i.id === id.id);

    if (foundList.includes(inputValue))
      return { error: "Duplicate identifier" };

    // Search for the vehicle
    const foundVehicle = database.find(
      // This is like this because the VCN ID is returned as a number type
      // eslint-disable-next-line
      (vehicle) => vehicle[newIdObj.type] == newIdObj.id
    );

    if (!foundVehicle) return { error: "Vehicle Not Found" };

    unfoundVehicles.splice(idx, 1);

    dispatch({
      type: actionTypes.SET_STATE,
      payload: {
        foundVehicles: [
          ...state.foundVehicles,
          { ...foundVehicle, ...newIdObj },
        ],
        unfoundVehicles: unfoundVehicles,
        inputValue: "",
      },
    });
    return { success: "Vehicle Added to Search List" };
  };

  const searchVehicles = async (ids) => {
    // THIS IS WHERE WE WILL FETCH VEHICLES AND HANDLE SORTING
    ids = Array.isArray(ids) ? ids : [ids];

    const results = await searchVehiclesDb(ids);
    const found = [];
    const unfound = [];
    ids.forEach((id) => {
      const foundVehicle = results.find(
        // This is like this because the VCN ID is returned as a number type
        // eslint-disable-next-line
        (vehicle) => vehicle[id.type.toLowerCase()] == id.id
      );
      if (foundVehicle) {
        if (foundVehicle.lat > 0 && foundVehicle.lat < 60) {
          found.push({
            ...id,
            ...foundVehicle,
            lng: parseFloat(foundVehicle.lng),
            lat: parseFloat(foundVehicle.lat),
          });
        } else {
          const { lat, lng, ...rest } = foundVehicle;
          unfound.push({ ...id, ...rest, reason: "INVALID COORDINATES" });
        }
      } else {
        unfound.push({ ...id, reason: "NOT IN DATABASE" });
      }
    });
    dispatch({
      type: actionTypes.SET_STATE,
      payload: {
        foundVehicles: [...state.foundVehicles, ...found],
        unfoundVehicles: [...state.unfoundVehicles, ...unfound],
        searchList: [],
      },
    });
  };

  const addToSearchList = (ids) => {
    dispatch({
      type: actionTypes.SET_STATE,
      payload: { searchList: [...state.searchList, ...ids], inputValue: "" },
    });
  };

  const setDetailVehicle = (vehicle) => {
    const currentVehicle = state.detailVehicle;
    const newVehicle = vehicle?.id === currentVehicle?.id ? {} : vehicle;
    dispatch({
      type: actionTypes.SET_STATE,
      payload: { detailVehicle: newVehicle },
    });
  };

  return {
    initializeState,
    setInputValue,
    validateAndAddToContext,
    removeFromSearchList,
    removeInvalidIds,
    removeFromFound,
    removeFromUnfound,
    clearSearchList,
    editInvalidId,
    editUnfoundId,
    searchVehicles,
    addToSearchList,
    setDetailVehicle,
  };
};

export { getActions, actionTypes };
