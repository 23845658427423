import React from "react";
import style from "./ComponentBoard.module.css";
import CommonButton from "../../components/common/CommonButton";
import { ReactComponent as Upload } from "../../assets/icons/Upload.svg";
import { ReactComponent as Copy } from "../../assets/icons/Copy.svg";
import { ReactComponent as List } from "../../assets/icons/List.svg";

export default function ComponentBoard() {
  return (
    <div className={style.container}>
      <div className={style.heading}>COMPONENT BOARD</div>
      <h1>SUBARU OF INDIANA - h1 (Card Title)</h1>
      <h2>SUBARU OF INDIANA - h2 (Table List Header)</h2>
      <CommonButton type="text" label="SUBARU OF INDIANA" />
      Subaru of Indiana - Default text (List Item Text)
      <div className="label-text">
        Subaru of Indiana - className="label-text" - (List Item Label Text)
      </div>
      <div className="details-text">
        Subaru of Indiana - className="details-text" - (List Item Vehicle
        Details)
      </div>
      <div className="info-text">
        Subaru of Indiana - className="info-text" - (Informational Text)
      </div>
      <CommonButton version="primary" width="200px" label="Search" />
      <CommonButton
        version="secondary"
        width="375px"
        label="CSV Import"
        icon={<Upload />}
      />
      <CommonButton
        version="secondary"
        size="small"
        width="375px"
        label="Start a New Search"
      />
      <CommonButton
        type="text"
        label="COPY VEHICLE LOCATION & DETAILS"
        icon={<Copy className="blue" />}
      />
      <CommonButton
        type="icon"
        version="primary"
        icon={<List className="white" />}
      />
      <CommonButton type="icon" version="secondary" icon={<List />} />
    </div>
  );
}
