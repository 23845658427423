import { initialSearchContextValues } from "./provider";
import { actionTypes } from "./actions";

export default function SearchReducer(state, action) {
  switch (action.type) {
    case actionTypes.INIT_STATE:
      return { ...initialSearchContextValues };
    case actionTypes.SET_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_INPUT_VALUE:
      return {
        ...state,
        inputValue: action.payload,
      };
    case actionTypes.SET_SEARCH_LIST:
      return {
        ...state,
        searchList: action.payload,
        inputValue: "",
      };
    case actionTypes.SET_INVALID_IDS:
      return {
        ...state,
        invalidIds: action.payload,
      };
    default:
      return state;
  }
}
