import React, { useContext } from "react";
import { SearchContext } from "../../context/search/provider";
import styles from "./SearchList.module.css";
import { ReactComponent as List } from "../../assets/icons/List.svg";
import { ReactComponent as ClearList } from "../../assets/icons/ClearList.svg";
import CommonButton from "../common/CommonButton";
import SearchListItem from "./SearchListItem";

export default function SearchList() {
  const { searchList, clearSearchList, removeFromSearchList } =
    useContext(SearchContext);
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={"d-flex align-items-center"}>
          <List style={{ marginRight: 12 }} />
          <h2>VEHICLE SEARCH LIST ({searchList.length})</h2>
        </div>
        {!!searchList.length && (
          <CommonButton
            onClick={clearSearchList}
            type="text"
            style={{ alignSelf: "center" }}
            icon={<ClearList className="blue" style={{ marginRight: 12 }} />}
            label="Clear Search List"
          />
        )}
      </div>
      <div className={styles.body}>
        {searchList.map((id) => (
          <SearchListItem key={id.id} item={id} remove={removeFromSearchList} />
        ))}
      </div>
    </div>
  );
}
