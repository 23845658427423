import React from "react";

export default function DetailItem({ label, value, warning }) {
  return (
    <div>
      <div style={{ marginRight: 50 }}>
        <div className="details-text">{label}</div>
        <h2
          style={{ fontSize: 18, color: warning ? "var(--warning)" : "black" }}
        >
          {value}
        </h2>
      </div>
    </div>
  );
}
