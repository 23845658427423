import React, { useEffect, useState } from "react";
import styles from "./ExpandableList.module.css";
import { ReactComponent as ClearList } from "../../assets/icons/ClearList.svg";
import { ReactComponent as Close } from "../../assets/icons/Close.svg";

export default function ExpandableList({
  title,
  icon,
  message,
  handleClearList,
  listItems,
  removeIds,
  name,
  expandedList,
  setExpandedList,
  className,
  style,
  editFunction,
  expanded,
  close,
  ListItem,
  topSection,
  headerStyles = {},
  onItemClick,
}) {
  const [isExpanded, setIsExpanded] = useState(expanded);

  useEffect(() => {
    if (isExpanded && expandedList !== name) {
      setIsExpanded(false);
    }
    // eslint-disable-next-line
  }, [expandedList]);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  return (
    <div
      className={`${styles.outerWrapper} ${
        isExpanded && styles.expanded
      } ${className}
      `}
      style={style}
    >
      <div className={`${styles.container} ${isExpanded || "box-shadow"}`}>
        <div
          className={`${styles.header} ${isExpanded && styles.headerExpanded}`}
          style={{ cursor: close ? "default" : "pointer" }}
          onClick={() => {
            close || setIsExpanded(!isExpanded);
            setExpandedList && setExpandedList(isExpanded ? "" : name);
          }}
        >
          <div className={styles.heading} style={headerStyles}>
            {icon}
            <h2 style={{ marginLeft: 16 }}>{title}</h2>
          </div>
          {isExpanded && handleClearList && (
            <ClearList
              onClick={handleClearList}
              className="blue hover-opacity"
            />
          )}
          {isExpanded && close && (
            <Close onClick={close} style={{ cursor: "pointer" }} />
          )}
        </div>
      </div>
      {isExpanded && (
        <div className={styles.scroll}>
          <div
            className={`info-text ${styles.infoText}`}
            style={isExpanded ? { marginBottom: 0 } : {}}
          >
            {message}
          </div>
          {topSection}
          <div className={styles.list}>
            {listItems.map((item) => {
              return (
                <ListItem
                  key={item.id}
                  item={item}
                  remove={removeIds}
                  edit={editFunction}
                  onClick={() => onItemClick?.(item)}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
