import React, { useState } from "react";
import { ReactComponent as Satellite } from "../../assets/icons/Satellite.svg";
import { ReactComponent as TerrainButton } from "../../assets/icons/Terrain.svg";
import styles from "./MapButtons.module.css";

export default function MapButtons({ setIsTerrainView, isTerrainView }) {
  const [isTerrainHover, setIsTerrainHover] = useState(false);
  const [isSatelliteHover, setIsSatelliteHover] = useState(false);

  const buttonContents = [
    {
      icon: (
        <Satellite
          onMouseEnter={() => setIsSatelliteHover(true)}
          onMouseLeave={() => setIsSatelliteHover(false)}
          style={!isTerrainView ? {} : { cursor: "pointer" }}
          fill={
            isTerrainView & isSatelliteHover
              ? "#232323"
              : isTerrainView
              ? "#707070"
              : "#0099ff"
          }
        />
      ),
      key: "satelliteButton",
      onClick: () => {
        setIsTerrainView(false);
      },
    },
    {
      icon: (
        <TerrainButton
          onMouseEnter={() => setIsTerrainHover(true)}
          onMouseLeave={() => setIsTerrainHover(false)}
          style={isTerrainView ? {} : { cursor: "pointer" }}
          fill={
            !isTerrainView & isTerrainHover
              ? "#232323"
              : isTerrainView
              ? "#0099ff"
              : "#707070"
          }
        />
      ),
      key: "terrainButton",
      onClick: () => {
        setIsTerrainView(true);
      },
    },
  ];

  return (
    <div className={styles.mapButtonsWrap}>
      {buttonContents.map((button) => {
        return (
          <div
            className={styles.iconWrap}
            key={button.key}
            onClick={button.onClick}
          >
            <div onClick={button.onClick}>{button.icon}</div>
          </div>
        );
      })}
    </div>
  );
}
