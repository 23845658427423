import React from "react";
import styles from "./CommonButton.module.css";

//---------- ABOUT THIS BUTTON ----------//
// VERSION OPTIONS: primary | secondary (required except with 'text' type buttons)
// TYPE OPTIONS: icon | text (defaults to regular button if not passed in)
// SIZE OPTIONS: small (defaults to regular button if not passed in)
// WIDTH: defaults to fit-content
//---------------------------------------//

export default function CommonButton({
  version,
  type,
  size,
  width,
  label,
  icon,
  onClick,
  disabled,
  className,
  style,
}) {
  return (
    <button
      onClick={() => disabled || onClick?.()}
      className={`${styles.button} ${styles[type]} ${styles[version]} ${
        styles[size]
      } ${disabled && styles.disabled} ${className}`}
      style={{ width, ...style }}
    >
      {icon && <div className={styles.iconDiv}>{icon}</div>}
      <div
        className={styles.label}
        style={{ marginLeft: type === "text" && icon ? 35 : 0 }}
      >
        {label}
      </div>
    </button>
  );
}
