import React, { useContext, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import * as XLSX from "xlsx";
import { ReactComponent as Checkmark } from "../../assets/icons/Checkmark.svg";
import { ReactComponent as Close } from "../../assets/icons/Close.svg";
import { SearchContext } from "../../context/search/provider";
import CommonButton from "../common/CommonButton";
import styles from "./ImportCSVModal.module.css";
import { CSVLink } from "react-csv";

export default function ImportCSVModal({ setIsImportingCSV }) {
  const { validateAndAddToContext } = useContext(SearchContext);
  const inputRef = useRef(null);
  const [currentFile, setCurrentFile] = useState(null);
  const [stringData, setStringData] = useState("");
  const [isSuccessfulUpload, setIsSuccessfulUpload] = useState(false);
  const [importResults, setImportResults] = useState([]);
  const [duplicateResults, setDuplicateResults] = useState([]);
  const [failedResults, setFailedResults] = useState([]);
  const headerText = "Vehicle Id Number";

  const exampleCSV = [
    [headerText],
    ["7J3ZZ56T783450000"],
    ["7J3ZZ56T"],
    ["500003D"],
    ["6T78345123"],
  ];

  const handleFileUpload = (e) => {
    const file = e.target?.files[0] || e[0];
    file &&
      setCurrentFile({
        name: file.name,
        size: `${(file.size / 1000).toFixed(2)} KB`,
      });
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      setStringData(data);
    };
    reader.readAsBinaryString(file);
  };

  const formatImportedString = (string) => {
    const split = string
      .split("\n")
      .map((id) => id.replaceAll(",", "").replaceAll('"', ""))
      .filter((id) => id !== "" && id !== headerText);
    return split.join(",");
  };

  return (
    <div className={styles.modalWrap}>
      <div
        className={styles.modalBackground}
        onClick={() => setIsImportingCSV(false)}
      />
      <div className={styles.modal}>
        <div className={styles.modalBox}>
          <div className={styles.closeWrapper}>
            <Close
              className={styles.closeButton}
              onClick={() => setIsImportingCSV(false)}
            />
          </div>
          <div className={styles.modalTitle}>
            Upload a CSV of vehicle identifiers. Download this{" "}
            <CSVLink
              data={exampleCSV}
              className={styles.link}
              target="_blank"
              filename={"example.csv"}
            >
              example.csv
            </CSVLink>{" "}
            to learn how to properly format your import file.
          </div>
          {!currentFile && (
            <>
              <input
                className={styles.file}
                ref={inputRef}
                id="file"
                type="file"
                accept=".csv"
                onChange={handleFileUpload}
              />
              <Dropzone onDrop={handleFileUpload}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div
                    {...getRootProps()}
                    className={`${styles.dropbox} ${
                      isDragActive && styles.activeDropbox
                    }`}
                    onClick={async () => {
                      inputRef.current.click();
                    }}
                  >
                    <input {...getInputProps()} />
                    <div>Drop File to Upload</div>
                    <div>or</div>
                    <div className={styles.link}>Browse Files</div>
                  </div>
                )}
              </Dropzone>
            </>
          )}
          {currentFile && isSuccessfulUpload && (
            <div className={styles.completion}>
              <Checkmark fill={"var(--primary)"} className={styles.checkmark} />
              <div style={{ marginBottom: 10 }}>
                <b>{currentFile?.name}</b> has been successfully uploaded.
              </div>
              <div>
                <b>
                  {importResults.length} vehicle
                  {importResults.length === 1 ? "" : "s"}
                </b>{" "}
                added to search list.
              </div>
              {!!duplicateResults.length && (
                <>
                  <div>
                    <b className={styles.failedImportText}>
                      {duplicateResults.length} vehicle id
                      {duplicateResults.length === 1 ? "" : "s"}
                    </b>{" "}
                    were duplicates already in your search list.
                  </div>
                </>
              )}
              {!!failedResults.length && (
                <>
                  <div>
                    <b className={styles.failedImportText}>
                      {failedResults.length} vehicle
                      {failedResults.length === 1 ? "" : "s"}
                    </b>{" "}
                    failed to import properly.
                  </div>
                </>
              )}
            </div>
          )}
          {currentFile && !isSuccessfulUpload && (
            <div className={styles.fileImportContainer}>
              <div>SELECTED FILE</div>
              <div className={styles.fileBox}>
                <div className={styles.fileName}>{currentFile.name}</div>
                <Close
                  className={styles.closeButton}
                  style={{ marginRight: 5, marginLeft: 5 }}
                  onClick={() => {
                    setCurrentFile(null);
                    setStringData("");
                  }}
                />
              </div>
              <div className={styles.searchButton}>
                <CommonButton
                  version="primary"
                  label="SUBMIT FILE"
                  width="100%"
                  onClick={() => {
                    const allImportedIdsCommaSeparatedString =
                      formatImportedString(stringData);
                    const results = validateAndAddToContext(
                      allImportedIdsCommaSeparatedString
                    );
                    setImportResults(results.validIds);
                    setFailedResults(results.invalidIds);
                    setDuplicateResults(results.duplicateIds);
                    setIsSuccessfulUpload(true);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
