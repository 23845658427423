import React, { useContext, useEffect, useState } from "react";
import styles from "./Search.module.css";
import FindAVehicle from "./FindAVehicle";
import CommonButton from "../common/CommonButton";
import SearchList from "./SearchList";
import ExpandableList from "./ExpandableList";
import { SearchContext } from "../../context/search/provider";
import ImportCSVModal from "./ImportCSVModal";
import { ReactComponent as Warning } from "../../assets/icons/Warning.svg";
import { ReactComponent as List } from "../../assets/icons/List.svg";
import { validateId } from "../../utils/validationHelper";
import { ErrorContext } from "../../context/error/provider";
import { useHistory } from "react-router-dom";
import SearchListItem from "./SearchListItem";
import { isAndroid, isIOS } from "react-device-detect";

export default function Search() {
  const history = useHistory();
  const {
    searchList,
    addToSearchList,
    invalidIds,
    removeInvalidIds,
    clearSearchList,
    removeFromSearchList,
    inputValue,
    editInvalidId,
    searchVehicles,
  } = useContext(SearchContext);
  const [errorMessage, setErrorMessage] = useContext(ErrorContext);
  const [expandedList, setExpandedList] = useState("");
  const [isImportingCSV, setIsImportingCSV] = useState(false);
  const [isSearchButtonEnabled, setIsSearchButtonEnabled] = useState(false);
  const [hasAddedToList, setHasAddedToList] = useState(false);
  useEffect(() => {
    if (!inputValue && errorMessage && !errorMessage.includes("duplicate")) {
      setErrorMessage("");
    }

    if ((searchList.length || inputValue.length) && !isSearchButtonEnabled) {
      setIsSearchButtonEnabled(true);
    } else if (
      !searchList.length &&
      !inputValue.length &&
      isSearchButtonEnabled
    ) {
      setIsSearchButtonEnabled(false);
    }
    // eslint-disable-next-line
  }, [inputValue, searchList]);

  const handleSearch = async () => {
    if (searchList.length) {
      await searchVehicles(searchList);
      history.push("/map");
    } else if (inputValue.length) {
      const idObj = validateId(inputValue);
      const isValid = idObj.type !== "invalid";
      if (isValid) {
        setErrorMessage("");
        addToSearchList([idObj]);
        await searchVehicles(idObj);
        history.push("/map");
      } else {
        setErrorMessage("Enter a valid vehicle identifier");
      }
    }
  };

  useEffect(() => {
    hasAddedToList && setExpandedList(searchList.length ? "search" : "invalid");
  }, [hasAddedToList, searchList.length]);

  return (
    <div className={styles.page}>
      {isImportingCSV && (
        <ImportCSVModal setIsImportingCSV={setIsImportingCSV} />
      )}
      <div className={styles.left}>
        <div
          className={`${styles.leftContent} ${
            !invalidIds.length && !searchList.length && "justify-content-center"
          }`}
        >
          <FindAVehicle
            setIsImportingCSV={setIsImportingCSV}
            errorMessage={errorMessage}
            handleHasAdded={() => hasAddedToList || setHasAddedToList(true)}
          />
          <div>
            {!!invalidIds.length && (
              <ExpandableList
                title={`INVALID IDENTIFIERS (${invalidIds.length})`}
                icon={<Warning />}
                message="Invalid identifiers will not be submitted with your vehicle search list."
                handleClearList={() => removeInvalidIds(invalidIds)}
                removeIds={removeInvalidIds}
                listItems={invalidIds}
                expandedList={expandedList}
                name="invalid"
                setExpandedList={setExpandedList}
                editFunction={editInvalidId}
                ListItem={SearchListItem}
                expanded={expandedList === "invalid"}
              />
            )}
            {!!searchList.length && (
              <ExpandableList
                title={`VEHICLE SEARCH LIST (${searchList.length})`}
                icon={<List />}
                handleClearList={clearSearchList}
                removeIds={removeFromSearchList}
                listItems={searchList}
                expandedList={expandedList}
                name="search"
                setExpandedList={setExpandedList}
                className={"mobile-only"}
                ListItem={SearchListItem}
                expanded={expandedList === "search"}
              />
            )}
          </div>
        </div>
        <div
          className={`${styles.searchButton} ${
            searchList.length || isAndroid || styles.hidden
          }`}
          style={
            isAndroid
              ? {
                  position: "fixed",
                  bottom: 0,
                  width: 400,
                }
              : {
                  position: "relative",
                  top: 0,
                  paddingBottom: isIOS ? 115 : 100,
                }
          }
        >
          <CommonButton
            version="primary"
            label="Search"
            width="100%"
            disabled={!isSearchButtonEnabled}
            onClick={handleSearch}
          />
        </div>
      </div>
      <div className={styles.right}>
        <SearchList />
      </div>
    </div>
  );
}
