import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { ErrorProvider } from "./context/error/provider";
import { SearchProvider } from "./context/search/provider";
import registerServiceWorker from "./registerServiceWorker";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <ErrorProvider>
      <SearchProvider>
        <App />
      </SearchProvider>
    </ErrorProvider>
  </BrowserRouter>,
  rootElement
);

registerServiceWorker();
