import React, { createContext, useReducer } from "react";
import SearchReducer from "./reducer";
import { getActions } from "./actions";

const SearchContext = createContext();

export const initialSearchContextValues = {
  inputValue: "",
  searchList: [],
  invalidIds: [],
  foundVehicles: [],
  unfoundVehicles: [],
  detailVehicle: {},
};

const SearchProvider = (props) => {
  const [state, dispatch] = useReducer(
    SearchReducer,
    initialSearchContextValues
  );

  let actions = getActions(state, dispatch);

  return (
    <SearchContext.Provider
      value={{
        ...state,
        ...actions,
      }}
      {...props}
    ></SearchContext.Provider>
  );
};

export { SearchContext, SearchProvider };
