import React, { useContext, useState } from "react";
import styles from "./AddVehicle.module.css";
import Input from "../common/Input";
import CommonButton from "../common/CommonButton";
import { ReactComponent as Add } from "../../assets/icons/Add.svg";
import { validateId } from "../../utils/validationHelper";
import { SearchContext } from "../../context/search/provider";
import { DESKTOP_BREAKPOINT, MOBILE_BREAKPOINT } from "../../utils/constants";

export default function AddVehicle({ windowWidth }) {
  const { searchVehicles, unfoundVehicles, foundVehicles } =
    useContext(SearchContext);
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = () => {
    const validatedId = validateId(inputValue);
    if (validatedId.type === "invalid") {
      setErrorMessage("Enter a valid identifier");
    } else if (
      unfoundVehicles.some((v) => v.id === validatedId.id) ||
      foundVehicles.some((v) => v.id === validatedId.id)
    ) {
      setErrorMessage("Duplicate identifier");
    } else {
      searchVehicles(validatedId);
      setInputValue("");
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.search}>
        <Input
          placeholder="Add Vehicle to Search List"
          value={inputValue}
          setValue={(value) => {
            setInputValue(value);
            if (errorMessage) {
              setErrorMessage("");
            }
          }}
          style={{ flex: 1, marginRight: 12 }}
          handleSubmit={() => inputValue && handleSubmit()}
        />
        <CommonButton
          type={
            (windowWidth < DESKTOP_BREAKPOINT &&
              windowWidth > MOBILE_BREAKPOINT) ||
            "icon"
          }
          label={
            windowWidth < DESKTOP_BREAKPOINT &&
            windowWidth > MOBILE_BREAKPOINT &&
            "Add Vehicle to List"
          }
          version="primary"
          icon={
            windowWidth < DESKTOP_BREAKPOINT ? (
              <Add className="white " />
            ) : (
              <Add className="white " height={35} width={35} />
            )
          }
          width={280}
          onClick={() => inputValue && handleSubmit()}
        />
      </div>
      {errorMessage && <div className={styles.error}>{errorMessage}</div>}
    </div>
  );
}
