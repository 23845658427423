import React from "react";
import styles from "./Input.module.css";

export default function Input({
  placeholder,
  value,
  setValue,
  style,
  handleSubmit,
}) {
  return (
    <input
      className={styles.input}
      value={value?.toUpperCase()}
      onChange={(e) => setValue(e.target.value)}
      placeholder={placeholder}
      style={style}
      onKeyPress={(e) => {
        const uppercasedValue = e.target.value.toUpperCase();
        e.key === "Enter" && handleSubmit(uppercasedValue);
      }}
    />
  );
}
