import React, { useContext, useEffect, useState } from "react";
import { SearchContext } from "../../context/search/provider";
import styles from "./VehicleDetails.module.css";
import { ReactComponent as Close } from "../../assets/icons/Close.svg";
import { ReactComponent as VehiclePin } from "../../assets/icons/VehiclePin.svg";
import { ReactComponent as Copy } from "../../assets/icons/Copy.svg";
import CommonButton from "../common/CommonButton";
import DetailItem from "./DetailItem";

export default function VehicleDetails() {
  const { detailVehicle, setDetailVehicle } = useContext(SearchContext);
  const [didCopyLocation, setDidCopyLocation] = useState(false);

  const idTypes = {
    VCN: "VCN-7",
    VIN8: "VIN-8",
    VIN: "VIN",
    SEQ: "SEQ",
  };

  useEffect(() => {
    let timeout;
    if (didCopyLocation) {
      timeout = setTimeout(() => setDidCopyLocation(false), 3000);
    }

    return () => clearTimeout(timeout);
  }, [didCopyLocation]);

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.header} `}>
        <div className={styles.heading}>
          <VehiclePin />
          <h2 style={{ marginLeft: 16 }}>VEHICLE DETAILS</h2>
        </div>

        <Close onClick={() => setDetailVehicle({})} className={"pointer"} />
      </div>
      <div className={styles.content}>
        <div className={styles.main}>
          <div className="details-text">{idTypes[detailVehicle.type]}</div>
          <h1>{detailVehicle.id}</h1>
          {detailVehicle.reason && (
            <div className={styles.info}>
              <DetailItem
                label="Reason Not Found"
                value={detailVehicle.reason}
                warning
              />
            </div>
          )}
          <div className={styles.info}>
            {detailVehicle.bodyType && (
              <DetailItem label="Body" value={detailVehicle.bodyType} />
            )}
            {detailVehicle.colorName && (
              <DetailItem label="Color" value={detailVehicle.colorName} />
            )}
          </div>
        </div>
        {!detailVehicle.reason && (
          <CommonButton
            type="text"
            label={
              didCopyLocation
                ? "Location Copied to Clipboard"
                : "Copy Vehicle Location & Details"
            }
            icon={<Copy className="blue" />}
            onClick={async () => {
              await navigator.clipboard.writeText(
                `${detailVehicle.lat},${detailVehicle.lng}`
              );
              setDidCopyLocation(true);
            }}
            style={{ padding: 0 }}
          />
        )}
      </div>
    </div>
  );
}
