import React, { useContext } from "react";
import { SearchContext } from "../../context/search/provider";
import CommonButton from "../common/CommonButton";
import styles from "./UnfoundWarning.module.css";
import { ReactComponent as Warning } from "../../assets/icons/Warning.svg";

export default function UnfoundWarning({ seeList }) {
  const { unfoundVehicles } = useContext(SearchContext);
  return (
    <div className={styles.container}>
      <div>
        <Warning fill="var(--warning)" style={{ marginRight: 10 }} />
        {`${unfoundVehicles.length} Vehicle${
          unfoundVehicles.length === 1 ? "" : "s"
        }
        Could Not Be Found`}
      </div>
      <CommonButton type="text" label="See List" onClick={seeList} />
    </div>
  );
}
