import React, { useContext, useEffect } from "react";
import CommonButton from "../common/CommonButton";
import Input from "../common/Input";
import styles from "./FindAVehicle.module.css";
import { ReactComponent as Upload } from "../../assets/icons/Upload.svg";
import { SearchContext } from "../../context/search/provider";
import { ErrorContext } from "../../context/error/provider";

export default function FindAVehicle({ setIsImportingCSV, handleHasAdded }) {
  const { setInputValue, inputValue, validateAndAddToContext } =
    useContext(SearchContext);
  const [errorMessage, setErrorMessage] = useContext(ErrorContext);
  const inputPlaceholder = "Search by VIN, VIN-8, SEQ, or VCN-7";

  useEffect(() => {
    let timer;

    if (errorMessage.includes("duplicate")) {
      timer = setTimeout(() => {
        setErrorMessage("");
      }, 2500);
    }

    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [errorMessage]);

  const handleSubmit = () => {
    const result = validateAndAddToContext(inputValue);
    handleHasAdded();
    let num = result.duplicateIds.length;
    if (num) {
      setErrorMessage(`${num} duplicate ID${num > 1 ? "s" : ""} found`);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.inputHeading}>
        FIND A VEHICLE
        <div className={styles.error}>{errorMessage}</div>
      </div>
      <Input
        placeholder={inputPlaceholder}
        value={inputValue}
        setValue={setInputValue}
        style={{ margin: "5px 0" }}
        handleSubmit={handleSubmit}
      />
      <div className="info-text desktop-only">
        Search multiple vehicles by a comma separated list
      </div>
      <CommonButton
        version="secondary"
        width="100%"
        label="Add Vehicle(s) to Search List"
        onClick={handleSubmit}
        disabled={!inputValue}
      />
      <div className="info-text mobile-only">
        Search multiple vehicles by starting a vehicle search list
      </div>
      <div className={styles.csvImport}>
        <CommonButton
          version="secondary"
          width="100%"
          label="CSV Import"
          icon={<Upload />}
          onClick={() => setIsImportingCSV(true)}
        />
      </div>
    </div>
  );
}
