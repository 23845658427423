export const validateId = (id) => {
  // VIN is 17 characters A-Z and 0-9
  // VIN-8 is 8 characters A-Z and 0-9
  // VCN-7 is 7 digits only
  // SEQ is last 4 of 10 digits
  id = id.toUpperCase().trim();

  switch (id.length) {
    case 17:
      return {
        id,
        type: /^[A-Z\d]+$/.test(id) ? "VIN" : "invalid",
      };
    case 8:
      return {
        id,
        type: /^[A-Z\d]+$/.test(id) ? "VIN8" : "invalid",
      };
    case 7:
      return {
        id,
        type: /^[A-Z]?[\d]+$/.test(id) ? "VCN" : "invalid",
      };
    case 4:
      return {
        id,
        type: /^[\d]+$/.test(id) ? "SEQ" : "invalid",
      };
    default:
      return {
        id,
        type: "invalid",
      };
  }
};
