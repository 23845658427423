import React, { createContext, useState } from "react";

export const ErrorContext = createContext();
export const ErrorProvider = (props) => {
  const [errorMessage, setErrorMessage] = useState("");
  return (
    <ErrorContext.Provider value={[errorMessage, setErrorMessage]}>
      {props.children}
    </ErrorContext.Provider>
  );
};
